<template>
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.5 0C13.7761 0 14 0.223858 14 0.5V2H15.5C15.7761 2 16 2.22386 16 2.5C16 2.77614 15.7761 3 15.5 3H14V4.5C14 4.77614 13.7761 5 13.5 5C13.2239 5 13 4.77614 13 4.5V3H11.5C11.2239 3 11 2.77614 11 2.5C11 2.22386 11.2239 2 11.5 2H13V0.5C13 0.223858 13.2239 0 13.5 0ZM7.9998 2C4.6862 2 2 4.6862 2 7.9998C2 9.49431 2.54643 10.8612 3.45041 11.9116C4.18218 10.8499 5.63104 9.51974 7.99595 9.50011L8.0001 9.50008C9.89267 9.50009 11.5613 10.456 12.5506 11.91C13.4537 10.8598 13.9996 9.49355 13.9996 7.9998C13.9996 7.72366 14.2235 7.4998 14.4996 7.4998C14.7757 7.4998 14.9996 7.72366 14.9996 7.9998C14.9996 11.8657 11.8657 14.9996 7.9998 14.9996C4.13392 14.9996 1 11.8657 1 7.9998C1 4.13392 4.13392 1 7.9998 1C8.27594 1 8.4998 1.22386 8.4998 1.5C8.4998 1.77614 8.27594 2 7.9998 2ZM11.8227 12.6242C11.0281 11.3487 9.61378 10.5008 8.00216 10.5001C5.94811 10.518 4.73746 11.7366 4.17676 12.6241C5.21484 13.4833 6.54702 13.9996 7.9998 13.9996C9.45251 13.9996 10.7846 13.4833 11.8227 12.6242ZM8 4.5C7.0335 4.5 6.25 5.2835 6.25 6.25C6.25 7.2165 7.0335 8 8 8C8.9665 8 9.75 7.2165 9.75 6.25C9.75 5.2835 8.9665 4.5 8 4.5ZM5.25 6.25C5.25 4.73122 6.48122 3.5 8 3.5C9.51878 3.5 10.75 4.73122 10.75 6.25C10.75 7.76878 9.51878 9 8 9C6.48122 9 5.25 7.76878 5.25 6.25Z"
			fill="currentColor"
		/>
	</svg>
</template>
